import React, { Component } from 'react';
import './App.css';
import { CSSTransition } from "react-transition-group";
import Social from './components/Social';

class App extends Component {
  render() {
    return (
      <div className="middle">
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="fade"
        >
          <h1>reynoldm.</h1>
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="fade2"
        >
          <Social />
        </CSSTransition>   
      </div>
    );
  }
}

export default App;
