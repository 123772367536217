import React, { Component } from "react";
import "./Social.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter,faGithub,faLinkedin,faKeybase } from "@fortawesome/free-brands-svg-icons"

class Social extends Component {
    render() {
        return(
            <div className="social">
                <a href="https://twitter.com/_reyery">
                    <FontAwesomeIcon icon={faTwitter} className="icon" />
                </a>
                <a href="https://github.com/reyery">
                    <FontAwesomeIcon icon={faGithub} className="icon" />
                </a>
                <a href="https://linkedin.com/in/reynoldm/">
                    <FontAwesomeIcon icon={faLinkedin} className="icon" />
                </a>
                <a href="https://keybase.io/reyery">
                    <FontAwesomeIcon icon={faKeybase} className="icon" />
                </a>
            </div>
        );
    }
}

export default Social;